import {ready} from "../utils/ready.mjs";
import {throttle} from "../utils/throttle.mjs";

;(function () {
    'use strict';

    // init main navigation
    ready(function () {
        var button = document.querySelector('.js-menu-skiplink-button');
        if (typeof(button) != 'undefined' && button != null) {
            var handler = new MenuSkiplinkHandler(button, 890);

            // handle skip link
            var skipLink = document.getElementById('skip-to-nav');
            skipLink.addEventListener('click', function (e) {
                handler.handleSkipLink(e);
            });
            skipLink.addEventListener('keydown', function (e) {
                if (e.keyCode === 13 || e.keyCode === 32) { // enter or space
                    handler.handleSkipLink(e);
                }
            });
        }
    });

    // Constructor
    function MenuSkiplinkHandler(button, desktopNavMinWidth)
    {

        var self = this;
        this.button = button;

        // get menu breakpoint width
        if (typeof(desktopNavMinWidth) === 'undefined' || desktopNavMinWidth == null) {
            this.desktopNavMinWidth = 890;
        } else {
            this.desktopNavMinWidth = desktopNavMinWidth;
        }
        this.isMobileView = true;

        window.addEventListener('resize', throttle(function () {
            var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            if (width >= self.desktopNavMinWidth) {
                // desktop view
                self.isMobileView = false;
            } else {
                // mobile view
                self.isMobileView = true;

                // handle nav hash in url
                if (window.location.hash && window.location.hash === "#nav") {
                    self.focusMenuButton();
                }
            }
        }, 200));

        if (typeof(Event) === 'function') {
            // modern browsers
            window.dispatchEvent(new Event('resize'));
        } else {
            // for IE and other old browsers
            // causes deprecation warning on modern browsers
            var evt = window.document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        }
    }

    // handle skip link
    MenuSkiplinkHandler.prototype.handleSkipLink = function (event) {
        if (this.isMobileView) {
            event.preventDefault();
            this.focusMenuButton();
        } else {
            event.preventDefault();
            document.getElementById('nav').focus();
        }
        return this;
    }

    // focus menu button
    MenuSkiplinkHandler.prototype.focusMenuButton = function () {
        this.button.focus();
        return this;
    }

    // Export MenuSkiplinkHandler
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = MenuSkiplinkHandler;
    } else if (typeof define === 'function' && define.amd) {
        define('MenuSkiplinkHandler', [], function () {
            return MenuSkiplinkHandler;
        })
    } else {
        // attach to window
        window.MenuSkiplinkHandler = MenuSkiplinkHandler;
    }
}());
